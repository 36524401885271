import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Layout, Col } from 'gatsby-theme-husky/src/layout';
import HeroBanner from 'gatsby-theme-husky/src/components/ArticleListingHeroBanner';
import ArticleBody from 'gatsby-theme-husky/src/components/ArticleBody';
import ArticleSubMenu from 'gatsby-theme-husky/src/components/ArticleSubMenu';
import ArticleDate from 'gatsby-theme-husky/src/components/ArticleDate';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles';
import { breakpointsArticles } from 'gatsby-theme-husky/src/components/RelatedArticles/constants';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { TArticlePageComponentProps } from 'gatsby-theme-husky/src/templates/ArticlePage/models';
import 'gatsby-theme-husky/src/templates/ArticlePage/ArticlePageMain.scss';
import 'gatsby-theme-husky/src/templates/ArticlePage/ArticlePageRTL.scss';

const ArticlePage: FC<TArticlePageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    page: {
      url,
      createDate,
      updateDate,
      lang,
      defaultCompositions,
      properties: {
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        title,
        articleBodyImageAltText,
        articleBody,
        bodySubnavigation,
        tags,
        topicsTitle,
        relatedArticlesTitle,
        relatedArticlesCTAButtonText,
        relatedArticlesCTAButtonAriaLabel,
      },
      image,
      imageSm,
      imageMd,
    },
    relatedArticles,
  },
}) => {
  const { isMobile, isTablet } = useScreenRecognition();

  const openGraphImage = image?.childImageSharp?.fluid.src;
  const { createDateText, updateDateText } = defaultCompositions.commonSettings;

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
        openGraphImage,
      }}
      className="blog-article-page"
    >
      <HeroBanner
        inFirstViewport
        url={url}
        title={title}
        image={isMobile ? imageSm : isTablet ? imageMd : image}
        hasVerticalPadding
        altImageText={articleBodyImageAltText}
        className="article-listing-hero-banner"
      />
      <Container className="blog-article-page__content">
        <Row>
          <Col lg="2" xl="2">
            <ArticleSubMenu
              content={articleBody}
              bodySubNavigation={bodySubnavigation}
              tags={tags}
              articleSubMenuTopics={topicsTitle}
            />
          </Col>
          <Col sm="12" md="12" lg="9" xl="9">
            <ArticleBody content={articleBody} />
            {createDateText && updateDateText ? (
              <ArticleDate
                createDateText={createDateText}
                updateDateText={updateDateText}
                createDate={createDate}
                updateDate={updateDate}
                lang={lang}
              />
            ) : null}
            <RelatedArticles
              breakpoints={breakpointsArticles}
              data={relatedArticles.nodes}
              title={relatedArticlesTitle}
              readMoreText={relatedArticlesCTAButtonText}
              ariaLabel={relatedArticlesCTAButtonAriaLabel || relatedArticlesCTAButtonText}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ArticlePageClAu($url: String = "", $links: [String] = "", $lang: String) {
    page: umbracoArticles(url: { eq: $url }, lang: { eq: $lang }) {
      url
      createDate
      updateDate
      lang
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      image: localImage {
        childImageSharp {
          fluid(maxWidth: 3400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSm: localImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageMd: localImage {
        childImageSharp {
          fluid(maxWidth: 1300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      properties {
        ...FragmentArticlePageProperties
        articleBodyImageAltText
        seoMetaDescription
        seoMetaTitle
        seoCanonicalUrl
        seoNoIndex
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        relatedArticlesTitle
        tags {
          name
          url {
            url
            name
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(filter: { url: { in: $links } }) {
      nodes {
        id
        ...FragmentRelatedArticle
        url
        localImage {
          childImageSharp {
            fluid(
              maxWidth: 767
              quality: 100
              sizes: "(max-width: 767px) 100vw, (max-width: 991px) 370px, 420px"
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;
export default ArticlePage;
